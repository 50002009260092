import { Container, ContainerHeader, Name, ContainerStatus, Active, ContainerInfo, ContainerAction } from './style'
import { Badge, Divider, Tooltip } from 'antd'
import ActionWhats from '../../assets/images/actionWhats.png'
import ActionEmail from '../../assets/images/actionEmail.png'
import ActionCall from '../../assets/images/actionCall.png'
import { MdPhone, MdOutlineMoreTime } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { ContainerEdit } from '../../pages/Team/ViewUser/styles'
import { EditOutlined } from '@material-ui/icons'
import { useState } from 'react'
import { ModalEditUser } from '../ModalEditUser'
import moment from 'moment'
import { clearCpf } from '../../utils/funcoes'

export interface IUserInfo {
  cpf: string
  email: string | undefined
  id: number
  is_active: boolean
  name: string
  state: string
  type: string
  phone: string | undefined
  sync_last_change?: string | null | undefined
  removal_start_date?: string | null | undefined
  removal_end_date?: string | null | undefined
  removal_id?: number | null | undefined
  removal_name?: string | null | undefined
  is_removal?: boolean
  celular_ms?: string
  celular_corporativo_ms?: string
  telefone_ms?: string
}

interface Props {
  data: IUserInfo
}

const CardUser = ({ data }: Props) => {
  const [openModalEdit, setOpenModalEdit] = useState(false)

  return (
    <Container>
      <Link to={data.type === 'GESTOR' ? '/backoffice/team/users' : `/backoffice/teamById/${data.id}`}>
        <ContainerHeader style={{ marginBottom: 0 }}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <img src={`https://via.placeholder.com/150/ff872c/fff/?text=${data.name.substr(0, 1)}`} alt='user' />
            <ContainerStatus>
              <Name>
                ({data.state}) <p>{data.name}</p>
              </Name>
            </ContainerStatus>
          </div>
          {data.is_active ? (
            <Active>Ativo</Active>
          ) : (
            <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>Inativo</Active>
          )}
          {/* <img src={Flag} className="giftFlag" /> */}
        </ContainerHeader>
        {data.is_removal && (
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Badge count={`Afastado: ${data.removal_name}`} />
              <div>
                <p
                  style={{
                    fontSize: 13,
                    color: '#302727',
                  }}
                >
                  De: {moment(data.removal_start_date).utc().format('DD/MM/YYYY')}
                </p>
                <p
                  style={{
                    fontSize: 13,
                    color: '#302727',
                  }}
                >
                  até: {moment(data.removal_end_date).utc().format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
          </div>
        )}
        <ContainerInfo>
          <MdPhone />
          {data.phone
            ? data.phone
            : data.celular_corporativo_ms
            ? data.celular_corporativo_ms
            : data.celular_ms
            ? data.celular_ms
            : data.telefone_ms
            ? data.telefone_ms
            : 'Não cadastrado'}
        </ContainerInfo>
        <Tooltip title='Ultima sincronização'>
          <ContainerInfo>
            <MdOutlineMoreTime />

            {data.sync_last_change !== null ? data.sync_last_change : '-'}
          </ContainerInfo>
        </Tooltip>

        <ContainerInfo>
          <p>CPF</p>
          {clearCpf(data.cpf)}
        </ContainerInfo>
      </Link>
      <Divider style={{ margin: 0 }} />
      <ContainerAction>
        <p>Ações Rápidas:</p>
        <Tooltip placement='top' title={<p style={{ margin: 0, color: '#272d3b' }}>WhatsApp</p>} color=' #eeddff'>
          <a
            href={`https://api.whatsapp.com/send?phone=${
              data.phone ? data.phone.replaceAll(' ', '').replaceAll('-', '') : ''
            }`}
            rel='noreferrer'
            target='_blank'
          >
            <img src={ActionWhats} alt='whats' />
          </a>
        </Tooltip>
        <Tooltip placement='top' title={<p style={{ margin: 0, color: '#272d3b' }}>Enviar e-mail</p>} color=' #eeddff'>
          <img src={ActionEmail} alt='email' />
        </Tooltip>
        <Tooltip
          placement='top'
          title={<p style={{ margin: 0, color: '#272d3b' }}>Ligar</p>}
          color=' #eeddff'
          style={{ color: '#000' }}
        >
          <img src={ActionCall} alt='phone' />
        </Tooltip>
        {data.type === 'GESTOR' && (
          <ContainerEdit
            onClick={() => setOpenModalEdit(!openModalEdit)}
            style={{ borderRadius: 5, marginLeft: 0, backgroundColor: '#ebebeb' }}
          >
            <Tooltip placement='topRight' title={'Editar cadastro'}>
              <EditOutlined />
            </Tooltip>
          </ContainerEdit>
        )}
      </ContainerAction>
      <ModalEditUser visible={openModalEdit} close={setOpenModalEdit} type='GESTOR' user={data} />
    </Container>
  )
}

export default CardUser
