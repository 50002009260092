import { useState, useEffect, useMemo, useRef } from 'react'
import { ColOrder, ColOrderAction, Container, ContainerOrder, ContainerPopover, OverFlowContainer } from './styles'
import { ListStatusKanban } from '../../../components/ListStatusKanban'
import { Popover, Button, Divider, Row, Col, Modal, message, Tooltip as TooltipAntd, Tag } from 'antd'
import { ContainerStatusKanban } from '../../../components/ContainerStatusKanban'
import { WhatsApp } from '@material-ui/icons'
import api_v2 from '../../../services/api-v2'
import { Loading } from '../../../components/Loading'
import { ButtonClear, ButtonSearch, ContainerSearch, LabelForm, Search } from '../../Team/Promoter/style'
import { MdSearch } from 'react-icons/md'
import { debounce } from 'lodash'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { ContainerButtonsFootter, ContainerFormFilter, GroupForm } from '../../../GlobalStyles'
import { FileSearchOutlined, ClearOutlined, CaretDownOutlined, FilterOutlined, CloseOutlined } from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import moment from 'moment'
import { Form } from '@unform/web'
import SimpleDate from '../../../components/form/SimpleDate'
import { RangePickerProps } from 'antd/lib/date-picker'
import SimpleSelectObject from '../../../components/form/SimpleSelectObject'
import { Search as SearchInput } from '../../../components/form/SimpleSearch'
import history from '../../../routes/history'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import { AddButtonWhite } from '../../../components/DefaultPage/style'
import { ContainerTagg } from '../../Survey/FormListPage/styles'

export interface IDataKanbam {
  batery: any
  id: number
  promoter_name: string
  store_complete: number
  store_counts: number
  store_name?: string
}

export const MonitoringPage = () => {
  const { location } = useReactRouter()
  const [visible, setVisible] = useState(false)
  const [typeView, setTypeView] = useState(0)
  const [noInteract, setNoInteract] = useState<IDataKanbam[]>([])
  const [inTransit, setInTransit] = useState<IDataKanbam[]>([])
  const [inStore, setInStore] = useState<IDataKanbam[]>([])
  const [done, setDone] = useState<IDataKanbam[]>([])
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filterTag, setFilterTag] = useState<any>({
    ref_date: null,
  })
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)
  const formRef = useRef<FormHandles>(null)

  const getList = async (body: any) => {
    // setLoading(true)
    const { ref_date, profile_id, state, city, sub_workspaces } = body
    setFilterTag(body)

    let filterQuery: any = {}

    if (ref_date) {
      filterQuery.ref_date = body.ref_date
      formRef.current!.setFieldValue('ref_date', moment(ref_date))
    }

    if (profile_id) {
      formRef.current!.setFieldValue('profile_id', profile_id)

      filterQuery.profile_id = body.profile_id.value
    }

    if (city) {
      formRef.current!.setFieldValue('city', city)

      filterQuery.city = body.city.value
    }

    if (state) {
      filterQuery.state = body.state.value
      formRef.current!.setFieldValue('state', state)
    }
    if (sub_workspaces) {
      formRef.current!.setFieldValue('sub_workspaces', sub_workspaces)

      filterQuery.sub_workspaces = body.sub_workspaces.value
    }

    const urlApi = qs.stringify(filterQuery)

    let { data } = await api_v2.get(`/kanban/2?${urlApi}`)

    setNoInteract(data.kanban.kanban.no_interaction)
    setInStore(data.kanban.kanban.in_store)
    setDone(data.kanban.kanban.finished)
    setInTransit(data.kanban.kanban.in_transit)
    // setLoading(false)
  }

  const searchName = (event: any, newIntransit: any, noInteractOrigin: any, inStoreOrigin: any, doneOrigin: any) => {
    let searchString = event.target.value
    if (!searchString) {
      return getStatusWithFilter(filterTag)
    }
    let newInteract = noInteractOrigin.filter((a: any) =>
      a.promoter_name.toLowerCase().includes(searchString.toLowerCase())
    )
    let newTransiti = newIntransit.filter((a: any) =>
      a.promoter_name.toLowerCase().includes(searchString.toLowerCase())
    )
    let newStore = inStoreOrigin.filter((a: any) => a.promoter_name.toLowerCase().includes(searchString.toLowerCase()))
    let newDone = doneOrigin.filter((a: any) => a.promoter_name.toLowerCase().includes(searchString.toLowerCase()))

    setNoInteract([...newInteract])
    setInTransit([...newTransiti])
    setInStore([...newStore])
    setDone([...newDone])
  }

  const debouncedChangeHandler = useMemo(() => debounce(searchName, 400), []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStatusWithFilter()
    const timer = setInterval(() => {
      getStatusWithFilter()
    }, 60000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNotify = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja notificar os promotores?',
      content: 'Promotores que não fizeram nenhum check-in até o momento receberá a mensagem',
      onOk() {
        message.success('Mensagens enviadas!')
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const cleanFilter = async () => {
    try {
      // setLoading(true)
      setFilterTag({ ref_date: moment().format('YYYY-MM-DD') })
      formRef.current?.reset()
      formRef.current?.clearField('state')
      formRef.current?.clearField('profile_id')
      formRef.current?.clearField('city')
      formRef.current?.clearField('sub_workspaces')
      let url: any = { ref_date: moment().format('YYYY-MM-DD') }

      history.push(`/backoffice/monitory/monitoring??${qs.stringify(url)}`)
      await getList({ ref_date: moment().format('YYYY-MM-DD') })
    } catch (error) {
      console.log(error)
    } finally {
      // setLoading(false)
    }
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  const getStatusWithFilter = async (data?: any) => {
    try {
      // setLoading(true)
      let obj: any = qs.parse(window.location.search.replaceAll('?', ''))

      const { ref_date, profile_id, state, city, sub_workspaces } = data ||
        obj || {
          ref_date: moment().format('YYYY-MM-DD'),
        }
      let url: any = {}
      if (!obj || !obj.ref_date) {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }

      if (ref_date) {
        obj.ref_date = ref_date
        url.ref_date = ref_date
      } else {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }
      if (profile_id && !Array.isArray(profile_id)) {
        obj.profile_id = typeof profile_id === 'string' ? JSON.parse(profile_id) : profile_id
        url.profile_id = typeof profile_id === 'string' ? profile_id : JSON.stringify(profile_id)
      }

      if (city && !Array.isArray(city)) {
        obj.city = typeof city === 'string' ? JSON.parse(city) : city
        url.city = typeof city === 'string' ? city : JSON.stringify(city)
      }

      if (state) {
        obj.state = typeof state === 'string' ? JSON.parse(state) : state
        url.state = typeof state === 'string' ? state : JSON.stringify(state)
      }

      if (sub_workspaces) {
        obj.sub_workspaces = typeof sub_workspaces === 'string' ? JSON.parse(sub_workspaces) : sub_workspaces
        url.sub_workspaces = typeof sub_workspaces === 'string' ? sub_workspaces : JSON.stringify(sub_workspaces)
      }

      history.push(`/backoffice/monitory/monitoring?${qs.stringify(url)}`)
      await getList(obj)
      setVisibleFilter(false)
      // setLoading(false)
    } catch (error) {
      history.push(`/backoffice/monitory/monitoring?ref_date=${moment().format('YYYY-MM-DD')}`)
    } finally {
      // setLoading(false)
    }
  }

  const removeFilter = async (typeRemove: any) => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    delete obj[typeRemove]
    formRef.current?.reset()

    try {
      // setLoading(true)
      const { ref_date, profile_id, state, city, sub_workspaces } = obj || {
        ref_date: moment().format('YYYY-MM-DD'),
      }
      let url: any = {}
      if (!obj || !obj.ref_date) {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }

      if (ref_date) {
        obj.ref_date = ref_date
        url.ref_date = ref_date
      } else {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }
      if (profile_id && !Array.isArray(profile_id)) {
        obj.profile_id = typeof profile_id === 'string' ? JSON.parse(profile_id) : profile_id
        url.profile_id = typeof profile_id === 'string' ? profile_id : JSON.stringify(profile_id)
      }

      if (city && !Array.isArray(city)) {
        obj.city = typeof city === 'string' ? JSON.parse(city) : city
        url.city = typeof city === 'string' ? city : JSON.stringify(city)
      }

      if (state) {
        obj.state = typeof state === 'string' ? JSON.parse(state) : state
        url.state = typeof state === 'string' ? state : JSON.stringify(state)
      }
      if (sub_workspaces) {
        obj.sub_workspaces = typeof sub_workspaces === 'string' ? JSON.parse(sub_workspaces) : sub_workspaces
        url.sub_workspaces = typeof sub_workspaces === 'string' ? sub_workspaces : JSON.stringify(sub_workspaces)
      }

      history.push(`/backoffice/monitory/monitoring?${qs.stringify(url)}`)
      await getList(obj)
      setVisibleFilter(false)
    } catch (error) {
      history.push(`/backoffice/monitory/monitoring?ref_date=${moment().format('YYYY-MM-DD')}`)
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    // getStatusWithFilter()
    // isRating()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <ContainerOrder>
        <ColOrder>
          <ContainerSearch style={{ marginRight: 15 }}>
            <Search
              placeholder='Buscar por nome'
              onChange={(e) => debouncedChangeHandler(e, inTransit, noInteract, inStore, done)}
            />
            <MdSearch size={20} color={'#A56EFC'} />
          </ContainerSearch>

          <ColOrderAction>
            <Popover
              content={
                <ContainerPopover>
                  <p
                    onClick={() => {
                      setTypeView(1)
                      setVisible(!visible)
                    }}
                  >
                    Tabelas
                  </p>
                  <Divider />
                  <p
                    onClick={() => {
                      setTypeView(0)
                      setVisible(!visible)
                    }}
                  >
                    Cards
                  </p>
                </ContainerPopover>
              }
              trigger='click'
              visible={visible}
              onVisibleChange={() => setVisible(!visible)}
              placement='left'
            >
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 5,
                  color: '#6f7782',
                  padding: 8,
                }}
              >
                <CaretDownOutlined style={{ fontSize: 20 }} />
                Visualizar como
              </Button>
            </Popover>
            <TooltipAntd placement='top' title={'Filtrar'}>
              <AddButtonWhite
                onClick={() => setVisibleFilter(!visibleFilter)}
                style={{ padding: 9, marginLeft: 10, marginRight: 15 }}
              >
                <FilterOutlined />
              </AddButtonWhite>
            </TooltipAntd>
          </ColOrderAction>
        </ColOrder>
      </ContainerOrder>
      <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'space-between', marginTop: 20 }}>
        <Col span={12} style={{ marginLeft: 10 }}>
          <Button onClick={handleNotify} icon={<WhatsApp style={{ fontSize: 16, marginRight: 10 }} />}>
            Notificar promotores
          </Button>
        </Col>
        <Col span={10}>
          <ContainerTagg>
            {filterTag && filterTag.ref_date && (
              <Tag color='geekblue' onClick={() => removeFilter('ref_date')}>
                {moment(filterTag.ref_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag && filterTag.profile_id && (
              <Tag color='geekblue' onClick={() => removeFilter('profile_id')}>
                {filterTag.profile_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag && filterTag.city && (
              <Tag color='geekblue' onClick={() => removeFilter('city')}>
                {filterTag.city.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag && filterTag.state && (
              <Tag color='geekblue' onClick={() => removeFilter('state')}>
                {filterTag.state.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag && filterTag.sub_workspaces && (
              <Tag color='geekblue' onClick={() => removeFilter('sub_workspaces')}>
                {filterTag.sub_workspaces.label}
                <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </div>
      {/* {!loading ? ( */}
      <>
        <Row>
          {typeView === 1 ? (
            <>
              <Row style={{width: '100%'}}>
                <ListStatusKanban data={noInteract} nameTable='Sem interação' />
                <ListStatusKanban data={inTransit} nameTable='Em trânsito' />
                <ListStatusKanban data={inStore} nameTable='Em loja' />
                <ListStatusKanban data={done} nameTable='Roteiro Completo' />
              </Row>
            </>
          ) : (
            <OverFlowContainer>
              <Row gutter={[10, 10]} style={{ flexWrap: 'nowrap' }} className='row-prop'>
                <Col style={{ width: '100%', maxWidth: '450px' }}>
                  <ContainerStatusKanban tag={filterTag} data={noInteract} nameTable='Sem Interação' />
                </Col>
                <Col style={{ width: '100%', maxWidth: '450px' }}>
                  <ContainerStatusKanban
                    tag={filterTag}
                    data={inTransit}
                    nameTable={
                      filterTag.ref_date && filterTag.ref_date !== moment().format('YYYY-MM-DD')
                        ? 'Roteiro incompleto'
                        : 'Em trânsito'
                    }
                  />
                </Col>
                <Col style={{ width: '100%', maxWidth: '450px' }}>
                  <ContainerStatusKanban tag={filterTag} data={inStore} nameTable='Em loja' />
                </Col>
                <Col style={{ width: '100%', maxWidth: '450px' }}>
                  <ContainerStatusKanban tag={filterTag} data={done} nameTable='Roteiro Completo' />
                </Col>
              </Row>
            </OverFlowContainer>
          )}
        </Row>
      </>
      {/* ) : (
        <Loading />
      )} */}
      {/* {loading && <Loading />} */}
      <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getStatusWithFilter}>
        <DrawerFilter
          visible={visibleFilter}
          close={setVisibleFilter}
          title={'Kanban'}
          footer={
            <ContainerButtonsFootter>
              <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
                <FileSearchOutlined />
                Pesquisar
              </ButtonSearch>
              <ButtonClear onClick={() => cleanFilter()}>
                <ClearOutlined />
                Limpar
              </ButtonClear>
            </ContainerButtonsFootter>
          }
        >
          <ContainerFormFilter
            tabIndex={0}
            onKeyDown={(evt: any) => {
              const isAntdSelect = evt.target.id.indexOf('select')

              if (evt.key === 'Enter' && isAntdSelect === -1) {
                formRef?.current?.submitForm()
              }
            }}
          >
            <GroupForm>
              <LabelForm>Selecione a data</LabelForm>
              <SimpleDate name='ref_date' disabledDate={disabledDate} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o perfil</LabelForm>
              <SearchInput placeholder='Selecione o perfil' name='profile_id' path='profiles' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <SimpleSelectObject
                placeholder='Selecione o estado'
                name='state'
                isMult={false}
                options={[
                  { value: 'AC', label: 'AC' },
                  { value: 'AL', label: 'AL' },
                  { value: 'AP', label: 'AP' },
                  { value: 'AM', label: 'AM' },
                  { value: 'BA', label: 'BA' },
                  { value: 'CE', label: 'CE' },
                  { value: 'DF', label: 'DF' },
                  { value: 'ES', label: 'ES' },
                  { value: 'GO', label: 'GO' },
                  { value: 'MA', label: 'MA' },
                  { value: 'MT', label: 'MT' },
                  { value: 'MS', label: 'MS' },
                  { value: 'MG', label: 'MG' },
                  { value: 'PA', label: 'PA' },
                  { value: 'PB', label: 'PB' },
                  { value: 'PR', label: 'PR' },
                  { value: 'PE', label: 'PE' },
                  { value: 'PI', label: 'PI' },
                  { value: 'RJ', label: 'RJ' },
                  { value: 'RN', label: 'RN' },
                  { value: 'RS', label: 'RS' },
                  { value: 'RO', label: 'RO' },
                  { value: 'RR', label: 'RR' },
                  { value: 'SC', label: 'SC' },
                  { value: 'SP', label: 'SP' },
                  { value: 'SE', label: 'SE' },
                  { value: 'TO', label: 'TO' },
                ]}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a cidade</LabelForm>
              <SearchInput placeholder='Selecione a cidade' name='city' path='city' isMult={false} />
            </GroupForm>
            {newGetWorkspace.shared_environment ? (
              <GroupForm>
                <LabelForm>Selecione a empresa</LabelForm>
                <SearchInput
                  placeholder='Selecione a empresa'
                  name='sub_workspaces'
                  path='sub-workspaces'
                  isMult={false}
                />
              </GroupForm>
            ) : null}
          </ContainerFormFilter>
        </DrawerFilter>
      </Form>
    </Container>
  )
}
