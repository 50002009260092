import { CloudUploadOutlined, EditOutlined } from '@material-ui/icons'
import { Button, Col, Dropdown, Menu, message, Modal, Row, Tabs, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { MdBattery50, MdEmail, MdPhone, MdRemoveCircleOutline } from 'react-icons/md'
import { useParams } from 'react-router'
import { IUserInfo } from '../../../components/CardUser'
import { Loading } from '../../../components/Loading'
import ActionWhats from '../../../assets/images/actionWhats.png'
import ActionEmail from '../../../assets/images/actionEmail.png'
import ActionCall from '../../../assets/images/actionCall.png'
import {
  ContainerHeader,
  ContainerStatus,
  Name,
  ContainerInfo,
  ContainerAction,
  ContainerData,
  BigButton,
  ContainerActionBack,
  ButtonBack,
  ContainerActionsCalc,
  Active,
} from './styles'
import { ModalCreateUser } from '../../../components/ModalCreateUser'
import {
  PlusOutlined,
  LeftOutlined,
  ExceptionOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { TabGeneralUser } from '../../../components/TabGeneralUser'
import { TabsVisits } from '../../../components/TabsVisits'
import { TabsScript } from '../../../components/TabsScript'
import { TabsAward } from '../../../components/TabsAward'
import { ModalEditUser } from '../../../components/ModalEditUser'
import { TabsSurveys } from '../../../components/TabsSurveys'
import { ModalExportVisit } from '../../../components/ModalExportVisit'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { TabsPictures } from '../../../components/TabsPictures'
import { ModalPutAway } from '../../../components/ModalPutAway'
import { Mixpanel } from '../../../utils/mixpanel'
import useReactRouter from 'use-react-router'
import qs from 'querystring'
import history from '../../../routes/history'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ModalPassword } from '../../../components/ModalPassword'
import api_v2 from '../../../services/api-v2'
import { TbExchange } from 'react-icons/tb'
import { ModalMigrate } from '../../../components/ModalMigrate'
import { getDataUser } from '../../../utils/login'
import { BsPower } from 'react-icons/bs'
import { editUserNotPermited } from '../../../utils/addScript'
import { clearCpf } from '../../../utils/funcoes'

// const dateFormat = 'DD/MM/YYYY'
const { confirm } = Modal

export const ViewUser = () => {
  const { location } = useReactRouter()
  const { id }: any = useParams()
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<IUserInfo>({} as IUserInfo)
  const [openModal, setOpenModal] = useState(false)
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [openModalMigrate, setOpenModalMigrate] = useState(false)
  const [visibleExportVisit, setVisibleExportVisit] = useState(false)
  const [visibleModalPutAway, setVisibleModalPutAway] = useState(false)
  const [visibleModalPassword, setVisibleModalPassword] = useState(false)
  const [batterie, setBatterie] = useState<any>()

  const [dateFilter, setDateFilter] = useState({
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
  })
  const [defaultTab, setDefaultTab] = useState('1')

  const loadPerfis = async () => {
    setLoading(true)
    // await api.get(`/user/${id}`)
    const { data } = await api_v2.get(`/promoter/${id}`)

    // const { data } = await api_v2.get(`/user/${id}`)
    setUser(data[0])
    setLoading(false)
  }

  const loadBatterie = async () => {
    setLoading(true)
    const { data } = await api_v2.get(`/batterie/${id}`)
    setBatterie(data)
  }

  useEffect(() => {
    loadPerfis()
    loadBatterie()
    const loadTab = () => {
      try {
        let tab = window.sessionStorage.getItem('tabRocket')
        if (tab) {
          let convert = JSON.parse(tab)
          if (convert.user === id) setDefaultTab(convert.key)
          window.sessionStorage.removeItem('tabRocket')
        }
      } catch (error) {
        console.log(error)
      }
    }

    loadTab()
    loadPerfis()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const saveTab = (key: string) => {
    try {
      setDefaultTab(key)
      window.sessionStorage.setItem('tabRocket', JSON.stringify({ user: id, key: key }))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

    if (getUrl && getUrl.start_date && getUrl.end_date) {
      setDateFilter(getUrl)
    } else {
      history.push(
        `/backoffice/teamById/${id}?start_date=${moment(new Date()).format('YYYY-MM-DD')}&end_date=${moment(
          new Date()
        ).format('YYYY-MM-DD')}`
      )
      setDateFilter({
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD'),
      })
    }

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const updateUser = async () => {
    try {
      setLoading(true)
      await api_v2.put(`/user/${id}`, { ...user, is_active: !user.is_active, password: undefined })
      Mixpanel.track('Ativar | Inativar promotor', {
        params: { is_active: !user.is_active },
      })
      await loadPerfis()
      setLoading(false)
      message.success('Usuário editado com sucesso.')
    } catch (error) {
      setLoading(false)
      message.error('Erro ao editar usuário.')
    }
  }

  return (
    <div>
      {loading && <Loading />}
      {hasIndex(['team.add_promoter'], permissions) > -1 && (
        <ContainerActionsCalc className='ocultXs'>
          <BigButton
            style={{
              color: '#fff',
              backgroundColor: '#6600cc',
              border: 'none',
              width: 206,
            }}
            onClick={() => setOpenModal(true)}
          >
            <PlusOutlined style={{ fontSize: 20 }} />
            Adicionar {user.type === 'GESTOR' ? 'Gestor' : 'Promotor'}
          </BigButton>
        </ContainerActionsCalc>
      )}
      {user.name && (
        <>
          <Row justify='space-between' style={{ marginBottom: 30 }}>
            <Col xs={24} sm={24} md={20} lg={20} span={20}>
              <ContainerHeader>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img src={`https://via.placeholder.com/150/ff872c/fff/?text=${user.name.substr(0, 1)}`} alt='user' />
                  <ContainerStatus>
                    <Name>{user.name}</Name>
                  </ContainerStatus>
                </div>
                {user.is_active ? (
                  <Active>Ativo</Active>
                ) : (
                  <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>
                    Inativo
                  </Active>
                )}

                {batterie && batterie.battery &&
                  <Tooltip title={`Nível de bateria ${batterie.battery}%`}>
                    <MdBattery50 style={{ fontSize: 18 }} />
                  </Tooltip>
                }
                <Tooltip placement='topRight' title={'Ações'}>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key='0' icon={<EditOutlined />} onClick={() => setOpenModalEdit(!openModalEdit)}>
                          Editar
                        </Menu.Item>
                        <Menu.Item
                          key='1'
                          icon={<ExclamationCircleOutlined />}
                          onClick={() => {
                            if (editUserNotPermited()) return message.error('Você não tem autorização para ativar ou inativar usuário.')

                            if (user.is_active && user.type != 'GESTOR') {
                              Modal.confirm({
                                title: 'Deseja inativar ?',
                                icon: <ExclamationCircleOutlined />,
                                content: 'Ao inativar você irá deletar as visitas futuras.',
                                onOk() {
                                  updateUser()
                                },
                                onCancel() {
                                  console.log('Cancel')
                                },
                              })
                            } else {
                              updateUser()
                            }
                          }}
                        >
                          {user.is_active ? 'Inativar' : 'Ativar'}
                        </Menu.Item>
                        <Menu.Item
                          key='2'
                          icon={<ExceptionOutlined />}
                          onClick={() => setVisibleModalPassword(!visibleModalPassword)}
                        >
                          Mudar senha
                        </Menu.Item>
                        {/* <Menu.Item
                          key='2'
                          icon={<BsPower />}
                          onClick={() =>
                            Modal.confirm({
                              title: 'Deseja deslogar o promotor ?',
                              icon: <ExclamationCircleOutlined />,
                              content: 'Ao confirmar você irá fazer com que o promotor sejá automaticamente deslogado.',
                              async onOk() {
                                try {
                                  await api_v2.post('promoter/logout', {
                                    promoter_id: id,
                                    user_id: getDataUser().id,
                                  })
                                  message.success('Sucesso ao deslogar promotor.')
                                } catch (error) {
                                  message.error('Erro ao deslogar promotor.')
                                }
                              },
                              onCancel() {
                                console.log('Cancel')
                              },
                            })
                          }
                        >
                          Deslogar promotor
                        </Menu.Item> */}
                        <Menu.Item
                          key='3'
                          icon={<ExceptionOutlined />}
                          onClick={() => setVisibleModalPutAway(!visibleModalPutAway)}
                        >
                          Afastar
                        </Menu.Item>
                        <Menu.Item key='4' icon={<TbExchange />} onClick={() => setOpenModalMigrate(!openModalMigrate)}>
                          Migrar promotor
                        </Menu.Item>
                        {moment().isBetween(
                          moment(new Date(user.removal_start_date)).subtract(1, 'd'),
                          moment(new Date(user.removal_end_date)).add(1, 'd')
                        ) && (
                            <Menu.Item
                              key='5'
                              icon={<MdRemoveCircleOutline />}
                              onClick={() => {
                                confirm({
                                  title: 'Remover afastamento',
                                  icon: <ExclamationCircleOutlined />,
                                  content: 'Tem certeza que deseja remover o afastamento desse promotor ?',
                                  async onOk() {
                                    try {
                                      setLoading(true)
                                      await api_v2.delete(`user/delete-removal/${user.id}`)
                                      message.success('Sucesso ao remover afastamento')
                                      setTimeout(() => {
                                        window.location.reload()
                                      }, 1000)
                                    } catch (error) {
                                      message.error('Erro ao remover afastamento')
                                    } finally {
                                      setLoading(false)
                                    }
                                  },
                                  onCancel() {
                                    console.log('Cancel')
                                  },
                                })
                              }}
                            >
                              Remover afastamento
                            </Menu.Item>
                          )}
                      </Menu>
                    }
                  >
                    <Button size='small' shape='circle' icon={<MoreOutlined />} />
                  </Dropdown>
                </Tooltip>
                {/* <Tooltip title='Ultima sincronização'>
                <p style={{ fontSize: 11, fontStyle: 'italic' }}>
                  {data.promoter_last_change !== null && data.promoter_last_change}
                </p>
              </Tooltip> */}
              </ContainerHeader>
            </Col>
            <Col xs={16} sm={16} md={16} lg={4} span={4}>
              <ContainerActionBack>
                <Link to={user.type === 'GESTOR' ? '/backoffice/team/users' : '/backoffice/team/users/promoter'}>
                  <ButtonBack>
                    <LeftOutlined />
                    Voltar
                  </ButtonBack>
                </Link>
                <ButtonBack
                  style={{
                    backgroundColor: '#b56aff',
                    color: '#fff',
                    width: 126,
                  }}
                  onClick={() => setVisibleExportVisit(!visibleExportVisit)}
                >
                  <CloudUploadOutlined style={{ fontSize: 20 }} />
                  Exportar
                </ButtonBack>
              </ContainerActionBack>
            </Col>
          </Row>
          <Row>
            <ContainerData>
              <ContainerInfo>
                <MdPhone />
                {user.phone
                  ? user.phone
                  : user.celular_corporativo_ms
                    ? user.celular_corporativo_ms
                    : user.celular_ms
                      ? user.celular_ms
                      : user.telefone_ms
                        ? user.telefone_ms
                        : 'Não cadastrado'}
              </ContainerInfo>
              <ContainerInfo>
                <MdEmail />
                {user.email ? user.email : 'Não cadastrado'}
              </ContainerInfo>
              <ContainerInfo>
                <p>CPF</p>
                {clearCpf(user.cpf)}
              </ContainerInfo>
              <ContainerAction>
                <p>Ações Rápidas:</p>
                <Tooltip
                  placement='top'
                  title={<p style={{ margin: 0, color: '#272d3b' }}>WhatsApp</p>}
                  color=' #eeddff'
                >
                  <a
                    href={`https://api.whatsapp.com/send?phone=${user.phone ? user.phone.replaceAll(' ', '').replaceAll('-', '') : ''
                      }`}
                    rel='noreferrer'
                    target='_blank'
                  >
                    <img src={ActionWhats} alt='whats' />
                  </a>
                </Tooltip>
                <Tooltip
                  placement='top'
                  title={<p style={{ margin: 0, color: '#272d3b' }}>Enviar e-mail</p>}
                  color=' #eeddff'
                  className='ocultXs'
                >
                  <a href='mailto:raphael@systemhope.com.br'>
                    <img src={ActionEmail} alt='email' />
                  </a>
                </Tooltip>
                <Tooltip
                  placement='top'
                  title={<p style={{ margin: 0, color: '#272d3b' }}>Ligar</p>}
                  color=' #eeddff'
                  style={{ color: '#000' }}
                >
                  <a href='tel:5511977463625'>
                    <img src={ActionCall} alt='phone' />
                  </a>
                </Tooltip>
              </ContainerAction>
            </ContainerData>
          </Row>
        </>
      )}

      <Tabs activeKey={defaultTab} onTabClick={saveTab}>
        <Tabs.TabPane tab='Visão Geral' key='1'>
          <TabGeneralUser dateFilter={dateFilter} setterDateFilter={setDateFilter} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Visitas' key='2'>
          <TabsVisits dateFilter={dateFilter} setterDateFilter={setDateFilter} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Roteiros' key='3'>
          <TabsScript />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab='Gameficação' key='4' className='ocultXs'>
          <TabsAward />
        </Tabs.TabPane> */}
        <Tabs.TabPane tab='Pesquisas' key='5' className='ocultXs'>
          <TabsSurveys dateFilter={dateFilter} setterDateFilter={setDateFilter} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Fotos' key='6' className='ocultXs'>
          <TabsPictures dateFilter={dateFilter} setterDateFilter={setDateFilter} />
        </Tabs.TabPane>
      </Tabs>
      <ModalEditUser visible={openModalEdit} close={setOpenModalEdit} type='PROMOTER' user={user} />
      <ModalCreateUser visible={openModal} close={setOpenModal} type={user.type === 'GESTOR' ? 'GESTOR' : 'PROMOTER'} />
      <ModalExportVisit
        loading={(load: any) => setLoading(load)}
        type='promoter'
        visible={visibleExportVisit}
        close={setVisibleExportVisit}
        promotor={user}
      />
      <ModalPutAway visible={visibleModalPutAway} close={setVisibleModalPutAway} promoter={user} />
      <ModalPassword visible={visibleModalPassword} close={setVisibleModalPassword} promoter={user} />
      <ModalMigrate visible={openModalMigrate} close={setOpenModalMigrate} promoter={user} />
    </div>
  )
}
