import React, { useState, useRef, useEffect } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Input from '../form/SimpleInput'
import * as Yup from 'yup'
// import api from '../../services/api'
import { message, Select } from 'antd'
import InputMask from '../form/InputMask'
import { optionUF } from '../../utils/option-user'
import { Loading } from '../Loading'
import { useParams } from 'react-router'
import ApiCep from '../../services/ViaCep'
// import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Mixpanel } from '../../utils/mixpanel'
import api_v2 from '../../services/api-v2'
import { Search } from '../form/SimpleSearch'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  type: string
  user: any
}
interface IAdress {
  district: string
  city: string
  street: string
}

export const ModalEditUser = ({ visible, close, type, user }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [work, setWork] = useState<any>({ value: user.work_hour_id, label: user.work_hour_name })
  const [sup, setSup] = useState<any>({ value: user.superior_id, label: user.superior_name })
  const [checked, setChecked] = useState<boolean>(user.is_active)
  const [loading, setLoading] = useState<boolean>(false)
  const [states, setStates] = useState<any>(user.state)
  const [cep, setCep] = useState<any>()
  const [defaultAdress, setDefaultAdress] = useState<IAdress>({} as IAdress)
  const { id }: any = useParams()

  async function handleSubmit(body: any) {
    if (work?.value) {
      body.work_hour_id = work.value
    } else {
      delete body.work_hour_id
    }

    if (sup?.value) {
      body.superior_id = sup.value
    } else {
      delete body.superior_id
    }
    setLoading(true)
    try {
      if (body.cpf === '') {
        body.cpf = null
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('* Insira o nome completo'),
        // password: Yup.string().required('* Insira uma senha'),
        // cpf: Yup.string().required('* Insira um CPF válido'),
      })
      await schema.validate(body, {
        abortEarly: false,
      })
      let data: any = {
        is_active: checked,
        state: states,
        type,
        profile_id: 0,
        ...body,
      }
      await api_v2.put(`/promoter/${id}`, data)
      Mixpanel.track('Editando Usuário', {
        params: `userId: ${id}, dados: ${JSON.stringify(body)}`,
      })
      message.success('Usuário atualizado com sucesso')
      setTimeout(() => window.location.reload(), 2000)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // console.log(err)
        // const errorMessage: any = {err}
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
        // console.log(formRef.current!.getErrors())
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  const handleChange = (value: any) => {
    setStates(value)
  }

  const getViaCep = async (evt: any) => {
    let cep = evt.target.value.replace('-', '').replaceAll('_', '')
    if (cep.length === 8) {
      setCep(cep)
      let { data } = await ApiCep.SearchCep(cep)
      setDefaultAdress({
        district: data.bairro,
        city: data.localidade,
        street: data.stree,
      })
    }
  }

  useEffect(() => {
    console.log(user)
    setSup({ value: user.superior_id, label: user.superior_name })
    setChecked(user.is_active)
    setWork({ value: user.work_hour_id, label: user.work_hour_name })
    setDefaultAdress({
      district: user.district,
      city: user.city,
      street: user.street,
    })
    setCep(user.cep)
  }, [user])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={450}
    >
      {loading && <Loading />}

      <Form
        translate={undefined}
        className='form'
        ref={formRef}
        initialData={{ is_active: true }}
        onSubmit={handleSubmit}
      >
        <ContainerStep2>
          <h3>Editar cadastro {user.name} </h3>
          <img src={AddUser} alt='user' />

          <Input
            type='text'
            label='Nome completo *'
            name='name'
            placeholder='Nome completo'
            style={{ width: 400 }}
            defaultValue={user.name}
          />
          {/* <Input
            type='email'
            label='Email*'
            name='email'
            placeholder='Email'
            style={{ width: 400 }}
            defaultValue={user.email}
          />
          */}
          <InputMask
            name='cpf'
            placeholder='CPF '
            label='CPF '
            mask='999.999.999-99'
            style={{ width: 400 }}
            defaultValue={user.cpf}
          />
          <Input
            type='text'
            label='Nickname'
            name='nickname'
            placeholder='Apelido'
            style={{ width: 400 }}
            defaultValue={user.nickname}
          />
          {type === 'PROMOTER' && (
            <Input
              type='text'
              label='Telefone'
              name='phone'
              placeholder='Telefone'
              style={{ width: 400 }}
              defaultValue={user.phone}
            />
          )}
          <InputMask
            name='cep'
            placeholder='CEP '
            label='CEP'
            mask='99999-999'
            style={{ width: 400 }}
            onChange={getViaCep}
            defaultValue={cep}
            
          />
          <Input
            type='text'
            defaultValue={defaultAdress.street}
            label='Rua'
            name='street'
            placeholder='rua'
            style={{ width: 400 }}
          />
          <Input
            type='text'
            defaultValue={defaultAdress.district}
            label='Bairro'
            name='district'
            placeholder='bairro'
            style={{ width: 400 }}
          />
          <Input
            type='text'
            defaultValue={defaultAdress.city}
            label='Cidade'
            name='city'
            placeholder='cidade'
            style={{ width: 400 }}
          />
          <div style={{ width: '100%' }}>
            <p style={{ marginBottom: 5 }}>Coordenador</p>
            <Search
              label='Coordenador'
              placeholder='Coordenador'
              name='superior_id'
              path='promoter_super'
              value={sup}
              onChange={(evt) => setSup(evt)}
            />
          </div>
          <div style={{ width: '100%' }}>
            <p style={{ marginBottom: 5 }}>Intrajornada</p>
            <Search
              label='Intrajornada'
              placeholder='Intrajornada'
              name='work_hour_id'
              path='work_hour'
              value={work}
              onChange={(evt) => setWork(evt)}
            />
          </div>
          <LabelCheck style={{ width: '100%', textAlign: 'left', fontWeight: 400 }}>Selecione os estados*</LabelCheck>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder='Selecione os estados de atuação'
            onChange={handleChange}
            defaultValue={user.state}
          >
            {optionUF.map((option, index: number) => (
              <Select.Option value={option.value} key={index}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
          {/* <LabelCheck>Ativo?</LabelCheck>
          <InputCheckbox>
            <Checkbox
              name='is_active'
              checked={checked}
              defaultChecked={user.is_active ? true : false}
              onChange={(e: any) => {
                setChecked(e.target.checked)
              }}
            />
          </InputCheckbox> */}
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Editar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
